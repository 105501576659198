<template></template>

<script>
export default {
  name: "AssoiciateStudents",
  title: "Asociar estudiantes | GE ITR",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>
